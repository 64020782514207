/* eslint-disable complexity */
import { getState, actions } from '@redux';
import { isAuthenticated } from '@redux/selectors/profile';
import { toast } from 'react-toastify';
import { endpoints as ep } from './endpoints';

export const endpoints = ep;

// const isHasValidKey = obj => {
//   if (!obj) return false;
//   // eslint-disable-next-line no-restricted-syntax
//   for (const k in obj) {
//     if (obj[k]) return true;
//   }
//   return false;
// };

const getErrorMessage = target => {
  let error = '';
  try {
    const msg = JSON.parse(target.responseText);
    error = msg?.message || msg?.details || msg?.error;
  } catch (e) {
    console.log({ e });
    error = 'Please try again';
  }
  return error;
};

// eslint-disable-next-line complexity
export default ({
  endpoint,
  url,
  data = null,
  token = null,
  onSuccess = () => null,
  onFail = () => null,
  files,
  contentType,
  fileName = 'Filename',
  paramsWithSameName,
}) => {
  // const [protocol, path] = window.location.href.split('//');
  // const source = `${protocol }//${ path.split('/')[0]}`;
  return new Promise((resolve, reject) => {
    const succeed = d => {
      onSuccess(d);
      resolve(d);
    };
    const failed = (e, hideError) => {
      reject(e);
      onFail(e);
      if (!hideError) toast.error('error');
    };
    console.log('calling API  ', endpoint, data);
    const xhr = new XMLHttpRequest();
    const isGet = endpoint ? endpoint[0].toLowerCase() === 'get' : url[0].toLowerCase() === 'get';
    let urlParams = '';
    if (isGet && !!data) {
      urlParams = `?${Object.entries(data)
        ?.map(e => e.join('='))
        .join('&')}`;
    }
    if (data) {
      console.log('data in API call', data);
    }
    if (url) {
      xhr.open(url[0], url[1]);
    } else if (endpoint) {
      xhr.open(endpoint[0], endpoint[1] + urlParams);
    }
    const requestHeaders = {
      Accept: 'application/json',
      // source,
    };

    const isFormData = files?.length || paramsWithSameName;

    if (contentType) {
      requestHeaders['Content-Type'] = contentType;
    } else if (!isFormData) {
      requestHeaders['Content-Type'] = 'application/json';
    }

    const reduxStore = getState();
    if (!token) {
      // eslint-disable-next-line no-param-reassign
      token = reduxStore.auth.token;
      // const ck = new Cookies();
      // const user = ck.get('user');
      // if (user && user.token) {
      //   // eslint-disable-next-line no-param-reassign
      //   token = user.token;
      // }
    }

    if (token) requestHeaders.Authorization = `Bearer ${token}`;

    if (contentType) {
      fetch(endpoint[1] + urlParams, {
        method: endpoint[0],
        headers: requestHeaders,
      })
        .then(r => r.blob())
        .then(blob => {
          const link = document.createElement('a');
          link.href = window.URL.createObjectURL(new Blob([blob]));
          link.setAttribute('download', fileName);
          document.body.appendChild(link);
          link.click();
          link.parentNode.removeChild(link);
          succeed();
        })
        .catch(failed);
      return;
    }

    Object.keys(requestHeaders).forEach(header => {
      xhr.setRequestHeader(header, requestHeaders[header]);
    });

    xhr.onload = ({ target }) => {
      console.log('API status', target.status);

      const error = getErrorMessage(target);

      if (target.status === 401) {
        console.log('API failed', target);
        failed(error, true);
        if (isAuthenticated(getState())) actions.auth.logout();
        return;
      }
      if (target.status === 403) {
        actions.general.setDenied(true);
        // window.location = '/denied';
        return;
      }
      if (target.status > 299) {
        console.log('API failed', target);

        failed(error);
        return;
      }

      if (target.status === 204) {
        succeed({});
      } else {
        try {
          const responseJson = JSON.parse(target.responseText);
          console.log('API success ', endpoint, data, responseJson);
          succeed(responseJson);
        } catch (e) {
          console.log('API success but failed to parse to JSON');
          console.log({
            error: e,
            responseText: target.responseText,
          });
        }
      }
    };

    xhr.onerror = error => {
      console.log('API failed', error);
      error = getErrorMessage(error);
      failed(error);
    };

    if (isFormData) {
      const formData = new FormData();
      if (data) {
        Object.keys(data).forEach(key => formData.append(key, data[key]));
      }
      files?.forEach(f => formData.append(f.name, f.file));
      paramsWithSameName?.forEach(p => formData.append(p.name, p.value));
      xhr.send(formData);
    } else if (data) {
      xhr.send(JSON.stringify(data));
    } else {
      xhr.send();
    }
  });
};

import { getState } from '@redux';

export const formatFromPath = p => {
  if (!p) return p;
  return p.split('.').pop().split('?')[0];
};

export const interpolate = (inputStr, params) => {
  let str = inputStr;
  // eslint-disable-next-line no-restricted-syntax
  for (const [key, value] of Object.entries(params)) {
    const regex = RegExp(`{${key}}`, 'g');
    let matches;
    // eslint-disable-next-line no-cond-assign
    while ((matches = regex.exec(str)) !== null) str = str.replace(matches[0], `${value}`);
  }
  return str;
};

export const gapBetweenGpsInKm = (gps1, gps2) => {
  const [lat1, lon1] = gps1;
  const [lat2, lon2] = gps2;
  if (!lat1 || !lat2 || !lon1 || !lon2) return 0;
  const deg2rad = deg => deg * (Math.PI / 180);
  const R = 6371; // Radius of the earth in km
  const dLat = deg2rad(lat2 - lat1); // deg2rad below
  const dLon = deg2rad(lon2 - lon1);
  const a = Math.sin(dLat / 2) * Math.sin(dLat / 2) + Math.cos(deg2rad(lat1)) * Math.cos(deg2rad(lat2)) * Math.sin(dLon / 2) * Math.sin(dLon / 2);
  const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
  const d = R * c; // Distance in km
  return d;
};

export const stringToObjectChild = (str, obj) => {
  let sub = { ...obj };
  // eslint-disable-next-line no-restricted-syntax
  for (const key of str.split('.')) {
    sub = sub[key];
    if (!sub) return;
  }
  return sub;
};

export const displayId = id => {
  const ID_LENGHT = 6;
  const i = `${id}`;
  return `MYL ${i.padStart(ID_LENGHT + 1 - i.length, 0)}`;
};

// eslint-disable-next-line max-len, no-useless-escape
export const isEmail = s => /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
    s,
  );

export const isPartner = (u, isApprovedOnly) => u?.UserRoles?.some(r => r.RoleId === 1 && (!isApprovedOnly || r.verifiedAt));
export const partnerRequestStatus = u => {
  const role = u?.UserRoles?.find(r => r.RoleId === 1);
  if (!role) return 'partner_status_none';
  if (role.verifiedAt) return 'partner_status_approved';
  if (!role.submittedAt) return 'partner_status_draft';
  if (!role.verifiedAt && role.submittedAt) return 'partner_status_pending';
};

export const getLocalizedServiceName = name => {
  try {
    console.log(name);
    const obj = JSON.parse(name);
    return obj[getState().auth.profile?.language] || obj.en || name;
  } catch (e) {
    console.log(e);
    return name;
  }
};

export const isDropService = u => u?.UserRoles?.some(r => r.RoleId === 2);

export const isAdmin = u => u?.level === 10;
export const isChangeRequestRejected = u => !!u?.UserChangeRequest?.rejectionRemark;

export const isArrayEquals = (a = [], b = []) => {
  if (a.length !== b.length) return false;
  // eslint-disable-next-line no-restricted-syntax
  for (const _a of a) {
    if (!b.includes(_a)) return false;
  }
  return true;
};

import React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { useSelector } from 'react-redux';
import { actions } from '@redux';

export default () => {
  const dialog = useSelector(s => s.dialog.data);
  const open = !!dialog;

  const onCancel = () => {
    dialog?.onKo?.();
    actions.dialog.setDialog();
  };
  const onAgree = () => {
    dialog?.onOk?.();
    actions.dialog.setDialog();
  };

  return (
    <Dialog open={open} onClose={onCancel}>
      {!!dialog && (
        <>
          <DialogTitle>{dialog.title}</DialogTitle>
          <DialogContent>
            <DialogContentText>{dialog.body}</DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={onCancel}>{dialog.koTitle}</Button>
            <Button onClick={onAgree} autoFocus>
              {dialog.okTitle}
            </Button>
          </DialogActions>
        </>
      )}
    </Dialog>
  );
};

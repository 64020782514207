import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import Api, { endpoints } from '@api';

export const cancelOrder = createAsyncThunk('order/cancelOrder', async ({ id, data, onSuccess }, thunkAPI) => {
  try {
    await Api({ endpoint: endpoints.cancelOrder(id), data });
    onSuccess?.();
  } catch (e) {
    return thunkAPI.rejectWithValue(e);
  }
});

export const cancelJob = createAsyncThunk('order/cancelJob', async ({ id, data, onSuccess }, thunkAPI) => {
  try {
    await Api({ endpoint: endpoints.cancelJob(id), data });
    onSuccess?.();
  } catch (e) {
    return thunkAPI.rejectWithValue(e);
  }
});
export const sendQuotation = createAsyncThunk('order/sendQuotation', async ({ data, onSuccess }, thunkAPI) => {
  try {
    await Api({ endpoint: endpoints.sendQuotation(), data });
    onSuccess?.();
  } catch (e) {
    return thunkAPI.rejectWithValue(e);
  }
});
export const getOrder = createAsyncThunk('order/get', async (id, thunkAPI) => {
  try {
    return await Api({ endpoint: endpoints.getOrder(id) });
  } catch (e) {
    return thunkAPI.rejectWithValue(e);
  }
});
export const acceptOrder = createAsyncThunk('order/accept', async (id, thunkAPI) => {
  try {
    return await Api({ endpoint: endpoints.acceptOrder(id) });
  } catch (e) {
    return thunkAPI.rejectWithValue(e);
  }
});

export const getOrders = createAsyncThunk('orders/get', async (data, thunkAPI) => {
  try {
    return await Api({ endpoint: endpoints.getOrders(), data });
  } catch (e) {
    return thunkAPI.rejectWithValue(e);
  }
});
export const startJob = createAsyncThunk('order/startJob', async ({ id, onSuccess, ...arg }, thunkAPI) => {
  try {
    await Api({ endpoint: endpoints.startJob(id), ...arg });
    onSuccess?.();
  } catch (e) {
    return thunkAPI.rejectWithValue(e);
  }
});
export const endJob = createAsyncThunk('order/endJob', async ({ id, onSuccess, ...arg }, thunkAPI) => {
  try {
    await Api({ endpoint: endpoints.endJob(id), ...arg });
    onSuccess?.();
  } catch (e) {
    return thunkAPI.rejectWithValue(e);
  }
});
export const closeOrder = createAsyncThunk('order/close', async ({ id, onSuccess, ...arg }, thunkAPI) => {
  try {
    await Api({ endpoint: endpoints.closeOrder(id), ...arg });
    onSuccess?.();
  } catch (e) {
    return thunkAPI.rejectWithValue(e);
  }
});

const initialState = {
  data: [],
  is_loading: false,
  total: 0,
  perpage: 10,
  page: 1,
  selectedOrder: null,
};

export const slice = createSlice({
  name: 'orders',
  initialState,
  reducers: {},
  extraReducers: {
    [cancelOrder.fulfilled]: state => {
      state.is_loading = false;
      state.selectedOrder = null;
    },
    [cancelOrder.pending]: state => {
      state.is_loading = true;
    },
    [cancelOrder.rejected]: state => {
      state.is_loading = false;
    },
    [cancelJob.fulfilled]: state => {
      state.is_loading = false;
    },
    [cancelJob.pending]: state => {
      state.is_loading = true;
    },
    [cancelJob.rejected]: state => {
      state.is_loading = false;
    },
    [startJob.fulfilled]: state => {
      state.is_loading = false;
    },
    [startJob.pending]: state => {
      state.is_loading = true;
    },
    [startJob.rejected]: state => {
      state.is_loading = false;
    },
    [endJob.fulfilled]: state => {
      state.is_loading = false;
    },
    [endJob.pending]: state => {
      state.is_loading = true;
    },
    [endJob.rejected]: state => {
      state.is_loading = false;
    },
    [closeOrder.fulfilled]: state => {
      state.is_loading = false;
    },
    [closeOrder.pending]: state => {
      state.is_loading = true;
    },
    [closeOrder.rejected]: state => {
      state.is_loading = false;
    },
    [sendQuotation.fulfilled]: state => {
      state.is_loading = false;
    },
    [sendQuotation.pending]: state => {
      state.is_loading = true;
    },
    [sendQuotation.rejected]: state => {
      state.is_loading = false;
    },
    [getOrder.fulfilled]: (state, action) => {
      state.is_loading = false;
      state.selectedOrder = action.payload.data;
    },
    [getOrder.pending]: state => {
      state.is_loading = true;
    },
    [getOrder.rejected]: state => {
      state.is_loading = false;
    },
    [getOrders.fulfilled]: (state, action) => {
      state.is_loading = false;
      state.data = action.payload.data.rows;
      state.total = action.payload.data.count;
      state.perpage = action.payload.data.perpage;
      state.page = action.payload.data.page;
    },
    [getOrders.pending]: state => {
      state.is_loading = true;
    },
    [getOrders.rejected]: state => {
      state.is_loading = false;
    },
  },
});

export const { actions } = slice;

export default slice.reducer;

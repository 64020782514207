/* eslint-disable max-lines */
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import Api, { endpoints } from '@api';

export const createUserChangeRequest = createAsyncThunk('user/createUserChangeRequest', async ({ id, onSuccess, onFail, ...args }, thunkAPI) => {
  try {
    await Api({
      endpoint: endpoints.createUserChangeRequest(id),
      ...args.args,
    });
    onSuccess?.();
  } catch (e) {
    onFail?.();
    return thunkAPI.rejectWithValue(e);
  }
});
export const rejectUserChangeRequest = createAsyncThunk('user/rejectUserChangeRequest', async ({ id, data, onSuccess, onFail }, thunkAPI) => {
  try {
    await Api({
      endpoint: endpoints.rejectUserChangeRequest(id),
      data,
    });
    onSuccess?.();
  } catch (e) {
    onFail?.();
    return thunkAPI.rejectWithValue(e);
  }
});
export const approveUserChangeRequest = createAsyncThunk('user/approveUserChangeRequest', async ({ id, onSuccess, onFail }, thunkAPI) => {
  try {
    await Api({ endpoint: endpoints.approveUserChangeRequest(id) });
    onSuccess?.();
  } catch (e) {
    onFail?.();
    return thunkAPI.rejectWithValue(e);
  }
});
export const cancelUserChangeRequest = createAsyncThunk('user/cancelUserChangeRequest', async ({ id, onSuccess, onFail }, thunkAPI) => {
  try {
    await Api({ endpoint: endpoints.cancelUserChangeRequest(id) });
    onSuccess?.();
  } catch (e) {
    onFail?.();
    return thunkAPI.rejectWithValue(e);
  }
});
export const getUsers = createAsyncThunk('users/get', async (data, thunkAPI) => {
  try {
    return await Api({ endpoint: endpoints.getUsers(), data });
  } catch (e) {
    return thunkAPI.rejectWithValue(e);
  }
});
export const makeAdmin = createAsyncThunk('user/makeAdmin', async ({ id, onSuccess }, thunkAPI) => {
  try {
    await Api({ endpoint: endpoints.makeAdmin(id) });
    onSuccess?.();
  } catch (e) {
    return thunkAPI.rejectWithValue(e);
  }
});
export const cancelAdmin = createAsyncThunk('user/cancelAdmin', async ({ id, onSuccess }, thunkAPI) => {
  try {
    await Api({ endpoint: endpoints.cancelAdmin(id) });
    onSuccess?.();
  } catch (e) {
    return thunkAPI.rejectWithValue(e);
  }
});
export const approvePartner = createAsyncThunk('user/approvePartner', async ({ id, onSuccess }, thunkAPI) => {
  try {
    await Api({ endpoint: endpoints.approvePartner(id) });
    onSuccess?.();
  } catch (e) {
    return thunkAPI.rejectWithValue(e);
  }
});
export const cancelPartnerApproval = createAsyncThunk('user/cancelPartnerApproval', async ({ id, onSuccess }, thunkAPI) => {
  try {
    await Api({ endpoint: endpoints.cancelPartnerApproval(id) });
    onSuccess?.();
  } catch (e) {
    return thunkAPI.rejectWithValue(e);
  }
});
export const deleteUser = createAsyncThunk('user/delete', async ({ id, onSuccess }, thunkAPI) => {
  try {
    await Api({ endpoint: endpoints.deleteProfile(id) });
    onSuccess?.();
  } catch (e) {
    return thunkAPI.rejectWithValue(e);
  }
});
export const getUser = createAsyncThunk('user/get', async (id, thunkAPI) => {
  try {
    return await Api({ endpoint: endpoints.getProfile(id) });
  } catch (e) {
    return thunkAPI.rejectWithValue(e);
  }
});

export const updateUser = createAsyncThunk('users/updateUser', async ({ id, args }, thunkAPI) => {
  try {
    return await Api({
      endpoint: endpoints.updateProfile(id),
      ...args,
    });
  } catch (e) {
    return thunkAPI.rejectWithValue(e);
  }
});

const initialState = {
  data: [],
  is_loading: false,
  total: 0,
  perpage: 10,
  page: 1,
  selectedUser: null,
};

export const slice = createSlice({
  name: 'users',
  initialState,
  reducers: {},
  extraReducers: {
    [rejectUserChangeRequest.fulfilled]: state => {
      state.is_loading = false;
    },
    [rejectUserChangeRequest.pending]: state => {
      state.is_loading = true;
    },
    [rejectUserChangeRequest.rejected]: state => {
      state.is_loading = false;
    },
    [approveUserChangeRequest.fulfilled]: state => {
      state.is_loading = false;
    },
    [approveUserChangeRequest.pending]: state => {
      state.is_loading = true;
    },
    [approveUserChangeRequest.rejected]: state => {
      state.is_loading = false;
    },
    [cancelUserChangeRequest.fulfilled]: state => {
      state.is_loading = false;
    },
    [cancelUserChangeRequest.pending]: state => {
      state.is_loading = true;
    },
    [cancelUserChangeRequest.rejected]: state => {
      state.is_loading = false;
    },
    [createUserChangeRequest.fulfilled]: state => {
      state.is_loading = false;
    },
    [createUserChangeRequest.pending]: state => {
      state.is_loading = true;
    },
    [createUserChangeRequest.rejected]: state => {
      state.is_loading = false;
    },
    [updateUser.fulfilled]: (state, action) => {
      state.is_loading = false;
      state.selectedUser = action.payload.data;
    },
    [updateUser.pending]: state => {
      state.is_loading = true;
    },
    [updateUser.rejected]: state => {
      state.is_loading = false;
    },
    [cancelAdmin.fulfilled]: state => {
      state.is_loading = false;
    },
    [cancelAdmin.pending]: state => {
      state.is_loading = true;
    },
    [cancelAdmin.rejected]: state => {
      state.is_loading = false;
    },
    [cancelPartnerApproval.fulfilled]: state => {
      state.is_loading = false;
    },
    [cancelPartnerApproval.pending]: state => {
      state.is_loading = true;
    },
    [cancelPartnerApproval.rejected]: state => {
      state.is_loading = false;
    },
    [makeAdmin.fulfilled]: state => {
      state.is_loading = false;
    },
    [makeAdmin.pending]: state => {
      state.is_loading = true;
    },
    [makeAdmin.rejected]: state => {
      state.is_loading = false;
    },
    [approvePartner.fulfilled]: state => {
      state.is_loading = false;
    },
    [approvePartner.pending]: state => {
      state.is_loading = true;
    },
    [approvePartner.rejected]: state => {
      state.is_loading = false;
    },
    [deleteUser.fulfilled]: state => {
      state.is_loading = false;
      state.selectedUser = null;
    },
    [deleteUser.pending]: state => {
      state.is_loading = true;
    },
    [deleteUser.rejected]: state => {
      state.is_loading = false;
    },
    [getUser.fulfilled]: (state, action) => {
      state.is_loading = false;
      state.selectedUser = action.payload.data;
    },
    [getUser.pending]: state => {
      state.is_loading = true;
    },
    [getUser.rejected]: state => {
      state.is_loading = false;
    },
    [getUsers.fulfilled]: (state, action) => {
      state.is_loading = false;
      state.data = action.payload.data.rows;
      state.total = action.payload.data.count;
      state.perpage = action.payload.data.perpage;
      state.page = action.payload.data.page;
    },
    [getUsers.pending]: state => {
      state.is_loading = true;
    },
    [getUsers.rejected]: state => {
      state.is_loading = false;
    },
  },
});

export const { actions } = slice;

export default slice.reducer;

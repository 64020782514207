import React from 'react';
import useLocale from '@helpers/useLocale';
import { DialogContent, DialogContentText, DialogTitle, Dialog } from '@mui/material';
import { isAuthenticated } from '@redux/selectors/profile';
import { actions } from '@redux';
import { useSelector } from 'react-redux';
import Login from '@components/login';
import Transition from '@components/DialogTransition';

export default function AlertDialogSlide() {
  const open = useSelector(s => s.auth.is_show_login_popup);
  const is_loading = useSelector(s => s.auth.is_loading);
  const isAuth = useSelector(isAuthenticated);
  const { t } = useLocale();
  const handleClose = () => actions.auth.togglePopup(false);

  React.useEffect(handleClose, [isAuth]);

  return (
    <Dialog
      open={open && !isAuth}
      TransitionComponent={Transition}
      keepMounted
      onClose={handleClose}
      aria-describedby="alert-dialog-slide-description"
      // style={{ backgroundColor: '#272727' }}
      PaperProps={{ style: { backgroundColor: '#272727' } }}
    >
      <DialogTitle style={{ color: 'white' }}>{t('login.title')}</DialogTitle>
      <DialogContent className="vcs">
        <DialogContentText style={{ color: 'white' }}>{is_loading ? t('login.logging') : t('login.subtitle')}</DialogContentText>
        <div className="center">
          <Login />
        </div>
      </DialogContent>
    </Dialog>
  );
}

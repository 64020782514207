import React from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import NotFound from '@pages/notfound';
import { actions } from '@redux';
import { useSelector } from 'react-redux';

export default p => {
  const isShowNotFound = useSelector(s => s.config.isShowNotFound);
  const location = useLocation();
  const history = useHistory();
  React.useEffect(() => {
    history?.listen(() => {
      actions.general.setDenied(false);
    });
  }, [history]);

  React.useEffect(() => {
    // if (isShowNotFound) actions.config.setConfig({ key: 'isShowNotFound', value: false });
  }, [location?.href]);
  if (isShowNotFound) return <NotFound />;
  return p.children;
};

import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import Api, { endpoints } from '@api';

export const getQuotation = createAsyncThunk('quotation/get', async (id, thunkAPI) => {
  try {
    return await Api({ endpoint: endpoints.getQuotation(id) });
  } catch (e) {
    return thunkAPI.rejectWithValue(e);
  }
});
export const removeQuotation = createAsyncThunk('quotation/remove', async ({ id, onSuccess }, thunkAPI) => {
  try {
    await Api({ endpoint: endpoints.removeQuotation(id) });
    onSuccess?.();
  } catch (e) {
    return thunkAPI.rejectWithValue(e);
  }
});

const initialState = {
  data: null,
  is_loading: false,
};

export const slice = createSlice({
  name: 'quotation',
  initialState,
  reducers: {},
  extraReducers: {
    [getQuotation.fulfilled]: (state, action) => {
      state.is_loading = false;
      state.data = action.payload.data;
    },
    [getQuotation.pending]: state => {
      state.is_loading = true;
    },
    [getQuotation.rejected]: state => {
      state.is_loading = false;
    },
    [removeQuotation.fulfilled]: s => {
      s.is_loading = false;
    },
    [removeQuotation.pending]: s => {
      s.is_loading = true;
    },
    [removeQuotation.rejected]: s => {
      s.is_loading = false;
    },
  },
});

export const { actions } = slice;

export default slice.reducer;

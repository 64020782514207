/* eslint-disable */
import React from 'react';
import { Redirect, useLocation } from 'react-router-dom';
// import { useSelector } from 'react-redux';
// import { isAuthenticated } from '@redux/selectors/profile';

const Demo = React.lazy(() => import('@pages/demo'));
const Privacy = React.lazy(() => import('@pages/privacy'));
const Profile = React.lazy(() => import('@pages/profile'));
const RedirectPage = React.lazy(() => import('@pages/redirect'));
const RequestChange = React.lazy(() => import('@pages/requestChange'));
const Users = React.lazy(() => import('@pages/users'));
const NewQuotation = React.lazy(() => import('@pages/newquotation'));
const Quotation = React.lazy(() => import('@pages/quotation'));
const Services = React.lazy(() => import('@pages/services'));
const Order = React.lazy(() => import('@pages/order'));
const Orders = React.lazy(() => import('@pages/orders'));
const NewOrder = React.lazy(() => import('@pages/neworder'));
const Service = React.lazy(() => import('@pages/service'));
const Home = React.lazy(() => import('@pages/home'));
const NotFound = React.lazy(() => import('@pages/notfound'));

// const Root = () => {
//   const isAuth = useSelector(isAuthenticated);
//   return <Redirect to={isAuth ? '/home' : '/welcome'} />;
// };

const RedirectBack = () => {
  const location = useLocation();
  return (
    <Redirect
      to={{
        pathname: location.pathname.split('/').slice(0, -1).join('/'),
      }}
    />
  );
};

const orderRoutes = ['orders'];
const adminOrderRoutes = ['all-orders'];
const jobRoutes = ['accepted-jobs', 'new-jobs'];
const jobOrderRoutes = [...orderRoutes, ...jobRoutes];

const routes = [
  {
    path: '/demo',
    component: Demo,
  },
  {
    path: '/new-order',
    component: NewOrder,
  },
  {
    path: '/profile',
    component: Profile,
    private: true,
  },
  {
    path: '/users/:id(\\d+)',
    component: Profile,
    roles: ['admin'],
  },
  {
    path: '/users',
    component: Users,
    roles: ['admin'],
  },
  {
    path: '/profile/request-change',
    component: RequestChange,
    private: true,
  },
  // {
  //   path: '/users/:userId(\\d+)/request-change',
  //   component: RequestChange,
  //   roles: ['admin'],
  // },
  {
    path: `/:mode(${[...jobOrderRoutes, ...adminOrderRoutes].join('|')})/:orderId(\\d+)/quotation`,
    component: RedirectBack,
  },
  {
    path: `/:mode(${[...jobOrderRoutes, ...adminOrderRoutes].join('|')})/:orderId(\\d+)/quotation/new`,
    component: NewQuotation,
    roles: ['partner'],
  },
  {
    path: `/:mode(${[...jobOrderRoutes, ...adminOrderRoutes].join('|')})/:orderId(\\d+)/quotation/:id(\\d+)`,
    private: true,
    component: Quotation,
  },
  {
    path: `/:mode(${[...orderRoutes, ...adminOrderRoutes].join('|')})`,
    private: true,
    component: Orders,
  },
  {
    path: `/:mode(${jobRoutes.join('|')})`,
    component: Orders,
    roles: ['partner'],
  },
  {
    path: `/:mode(${[...orderRoutes, ...adminOrderRoutes].join('|')})/:id(\\d+)`,
    private: true,
    component: Order,
  },
  {
    path: `/:mode(${jobRoutes.join('|')})/:id(\\d+)`,
    roles: ['partner'],
    component: Order,
  },
  {
    path: '/services',
    component: Services,
    roles: ['admin'],
  },
  {
    path: '/services/:id',
    component: Service,
    roles: ['admin'],
  },
  {
    path: '/redirect',
    component: RedirectPage,
  },
  {
    path: '/privacy',
    fullPage: true,
    component: Privacy,
  },
  {
    path: '/',
    fullPage: true,
    component: Home,
  },
  {
    component: NotFound,
    exact: false,
    fullPage: true,
  },
];

export default routes.map(r => ({ ...r, exact: r.exact === false ? false : true }));

import React from 'react';
import { Link } from 'react-router-dom';
import useLocale from '@helpers/useLocale';

// eslint-disable-next-line complexity
export default ({ color = 'grey' }) => {
  const { t } = useLocale();

  return (
    <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
      <div style={{ color }}>{t('footer.copyright')}</div>
      <div>
        <Link to="/privacy" style={{ color }}>
          {t('footer.privacy')}
        </Link>
      </div>
    </div>
  );
};

import React from 'react';
import useLocale from '@helpers/useLocale';
import { isAuthenticated } from '@redux/selectors/profile';
import { useSelector } from 'react-redux';
import { actions } from '@redux';
import { Link } from 'react-router-dom';

import { AppBar, Avatar, Typography, Button, Drawer } from '@mui/material';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import MenuItem from '@mui/material/MenuItem';
import Menu from '@mui/material/Menu';
import MenuIcon from '@mui/icons-material/Menu';
import { Lock } from '@mui/icons-material';
import DrawerContent from '@components/Drawer';
import logo from '@res/logo.png';

import packageJson from '../../../package.json';

export default () => {
  const [isOpenDrawer, setIsOpenDrawer] = React.useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);

  const isMenuOpen = Boolean(anchorEl);

  const { t } = useLocale();
  const isAuth = useSelector(isAuthenticated);
  const { name, image } = useSelector(s => s.auth.profile) || {};
  // const is_loading = useSelector(s => s.auth.is_loading);

  const handleProfileMenuOpen = event => setAnchorEl(event.currentTarget);

  const handleMenuClose = () => setAnchorEl(null);

  const toggleDrawer = e => {
    if (e && e.type === 'keydown' && (e.key === 'Tab' || e.key === 'Shift')) return;
    setIsOpenDrawer(v => !v);
  };

  const profileClicked = e => {
    if (isAuth) handleProfileMenuOpen(e);
    else {
      actions.auth.togglePopup(true);
      handleMenuClose();
    }
  };

  const menuId = 'primary-search-account-menu';
  const renderMenu = (
    <Menu
      anchorEl={anchorEl}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right',
      }}
      id={menuId}
      keepMounted
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      open={isMenuOpen}
      onClose={handleMenuClose}
    >
      <Link to="/profile" style={{ color: 'black' }}>
        <MenuItem>{t('header.profile')}</MenuItem>
      </Link>
      <MenuItem
        onClick={() => {
          actions.auth.logout();
          handleMenuClose();
        }}
      >
        {t('header.logout')}
      </MenuItem>
    </Menu>
  );

  return (
    <Box sx={{ flexGrow: 1 }}>
      <AppBar position="sticky" style={{ backgroundColor: '#272727' }}>
        <Toolbar>
          {isAuth && (
            <IconButton
              size="large"
              edge="start"
              color="inherit"
              aria-label="open drawer"
              onClick={toggleDrawer}
              onMouseEnter={toggleDrawer}
              sx={{ mr: 2 }}
            >
              <MenuIcon />
            </IconButton>
          )}
          <div className="relative">
            <Link to="/">
              {/* <Typography
                variant="h6"
                noWrap
                component="div"
                className="shine"
                sx={{
                  display: { xs: 'block', sm: 'block' },
                  color: '#66fcf1',
                }}
              >
                {t('header.title')}
              </Typography> */}
              <img src={logo} alt="logo" title="logo" width="5rem" height="2rem" style={{ width: '5rem', height: '2rem' }} />
            </Link>
            <div className="absolute" style={{ opacity: 0.3, fontSize: 10, bottom: -8 }}>
              {packageJson.version}
            </div>
          </div>

          <Box sx={{ flexGrow: 1 }} />
          <Box sx={{ display: 'flex' }}>
            <Button className="hcs" aria-controls={menuId} aria-haspopup="true" onClick={profileClicked} color="inherit">
              {!!image ? <Avatar sx={{ width: 24, height: 24 }} alt="profile image" src={image} /> : <Lock />}
              <Typography>{isAuth ? name : t('header.login')}</Typography>
            </Button>
          </Box>
        </Toolbar>
      </AppBar>
      {renderMenu}
      {isAuth && (
        <Drawer
          // anchor={anchor}
          open={isOpenDrawer}
          onClose={toggleDrawer}
        >
          <DrawerContent close={toggleDrawer} />
        </Drawer>
      )}
    </Box>
  );
};

import React from 'react';
import { Button, CircularProgress } from '@mui/material';

export default ({ isLoading, disabled, children, ...p }) => (
  <Button variant="contained" {...p} disabled={disabled || isLoading}>
    <div style={{ display: 'flex', alignItems: 'center' }}>
      {isLoading && <CircularProgress size={16} />}
      <div style={{ marginLeft: 6 }}>{children}</div>
    </div>
  </Button>
);

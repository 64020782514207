import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

export const getAllConfig = createAsyncThunk('config/all', async (_, thunkAPI) => {
  try {
    return await fetch(process.env.REACT_APP_CONFIG_URL).then(r => r.json());
  } catch (e) {
    return thunkAPI.rejectWithValue(e);
  }
});

const initialState = {
  language: {},
  config: {},
  // isShowNotFound: false,
};

export const slice = createSlice({
  name: 'config',
  initialState,
  reducers: {
    // setConfig: (state, action) => {
    //   state[action.payload.key] = action.payload.value;
    // },
  },
  extraReducers: {
    [getAllConfig.fulfilled]: (state, action) => {
      state.language = action.payload.language;
      state.config = action.payload.config;
    },
  },
});

export const { actions } = slice;

export default slice.reducer;

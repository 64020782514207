import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import Api, { endpoints } from '@api';

export const submitPartnerRequest = createAsyncThunk('profile/submitPartnerRequest', async ({ onSuccess, id }, thunkAPI) => {
  try {
    await Api({ endpoint: endpoints.submitPartnerRequest(id) });
    onSuccess?.();
  } catch (e) {
    return thunkAPI.rejectWithValue(e);
  }
});
export const cancelPartnerRequest = createAsyncThunk('profile/cancelPartnerRequest', async ({ onSuccess, id }, thunkAPI) => {
  try {
    await Api({ endpoint: endpoints.cancelPartnerRequest(id) });
    onSuccess?.();
  } catch (e) {
    return thunkAPI.rejectWithValue(e);
  }
});

const initialState = {
  is_loading: false,
};

export const slice = createSlice({
  name: 'profile',
  initialState,
  reducers: {},
  extraReducers: {
    [submitPartnerRequest.fulfilled]: state => {
      state.is_loading = false;
    },
    [submitPartnerRequest.pending]: state => {
      state.is_loading = true;
    },
    [submitPartnerRequest.rejected]: state => {
      state.is_loading = false;
    },
    [cancelPartnerRequest.fulfilled]: state => {
      state.is_loading = false;
    },
    [cancelPartnerRequest.pending]: state => {
      state.is_loading = true;
    },
    [cancelPartnerRequest.rejected]: state => {
      state.is_loading = false;
    },
  },
});

export const { actions } = slice;

export default slice.reducer;

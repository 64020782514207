/* eslint-disable */

import { configureStore, combineReducers } from '@reduxjs/toolkit';
// import logger from 'redux-logger';
import { persistStore, persistReducer, FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import auth, * as authActions from './modules/auth';
import config, * as configActions from './modules/config';
import dialog, * as dialogActions from './modules/dialog';
import users, * as usersActions from './modules/users';
import services, * as servicesActions from './modules/services';
import profile, * as profileActions from './modules/profile';
import orders, * as ordersActions from './modules/orders';
import pincode, * as pincodeActions from './modules/pincode';
import neworder, * as neworderActions from './modules/neworder';
import quotation, * as quotationActions from './modules/quotation';
import general, * as generalActions from './modules/general';

const persistConfig = {
  key: 'root',
  version: 1,
  storage,
  // blacklist: ['auth', 'config'],
  whitelist: ['auth', 'config'],
};

const persistedReducer = persistReducer(
  persistConfig,
  combineReducers({
    auth,
    config,
    dialog,
    users,
    services,
    profile,
    orders,
    pincode,
    neworder,
    quotation,
    general,
  })
);

const isDebug = !process.env.NODE_ENV || process.env.NODE_ENV === 'development';

export const store = configureStore({
  devTools: true, //isDebug, // TODO: set back to isDebug after launch
  reducer: persistedReducer,
  middleware: m =>
    m({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    }).concat(...(isDebug ? [require('redux-logger').default] : [])),
  // }).concat(...(isDebug ? [logger] : [])),
});

export const persistor = persistStore(store);

const getStore = () => store;
export const getState = () => getStore()?.getState();
export const dispatch = d => getStore()?.dispatch(d);
export const actions = {
  auth: {
    logout: () => dispatch(authActions.logout()),
    thirdPartyLogin: d => dispatch(authActions.thirdPartyLogin(d)),
    deleteProfile: () => dispatch(authActions.deleteProfile()),
    addressList: () => dispatch(authActions.addressList()),
    getProfile: () => dispatch(authActions.getProfile()),
    updateProfile: d => dispatch(authActions.updateProfile(d)),
    togglePopup: d => dispatch(authActions.actions.togglePopup(d)),
  },
  config: {
    getAllConfig: () => dispatch(configActions.getAllConfig()),
    // setConfig: d => dispatch(configActions.actions.setConfig(d)),
  },
  dialog: {
    setDialog: d => dispatch(dialogActions.actions.setDialog(d)),
  },
  users: {
    updateUser: d => dispatch(usersActions.updateUser(d)),
    getUsers: d => dispatch(usersActions.getUsers(d)),
    approvePartner: d => dispatch(usersActions.approvePartner(d)),
    makeAdmin: d => dispatch(usersActions.makeAdmin(d)),
    cancelAdmin: d => dispatch(usersActions.cancelAdmin(d)),
    cancelPartnerApproval: d => dispatch(usersActions.cancelPartnerApproval(d)),
    deleteUser: d => dispatch(usersActions.deleteUser(d)),
    getUser: d => dispatch(usersActions.getUser(d)),
    createUserChangeRequest: d => dispatch(usersActions.createUserChangeRequest(d)),
    cancelUserChangeRequest: d => dispatch(usersActions.cancelUserChangeRequest(d)),
    approveUserChangeRequest: d => dispatch(usersActions.approveUserChangeRequest(d)),
    rejectUserChangeRequest: d => dispatch(usersActions.rejectUserChangeRequest(d)),
  },
  services: {
    getServices: () => dispatch(servicesActions.getServices()),
    getService: d => dispatch(servicesActions.getService(d)),
    updateService: d => dispatch(servicesActions.updateService(d)),
    createService: d => dispatch(servicesActions.createService(d)),
    updatePartnerService: d => dispatch(servicesActions.updatePartnerService(d)),
  },
  profile: {
    submitPartnerRequest: d => dispatch(profileActions.submitPartnerRequest(d)),
    cancelPartnerRequest: d => dispatch(profileActions.cancelPartnerRequest(d)),
  },
  orders: {
    getOrders: d => dispatch(ordersActions.getOrders(d)),
    getOrder: d => dispatch(ordersActions.getOrder(d)),
    cancelOrder: d => dispatch(ordersActions.cancelOrder(d)),
    sendQuotation: d => dispatch(ordersActions.sendQuotation(d)),
    startJob: d => dispatch(ordersActions.startJob(d)),
    cancelJob: d => dispatch(ordersActions.cancelJob(d)),
    endJob: d => dispatch(ordersActions.endJob(d)),
    closeOrder: d => dispatch(ordersActions.closeOrder(d)),
  },
  pincode: {
    resetPincode: d => dispatch(pincodeActions.resetPincode(d)),
    updatePincode: d => dispatch(pincodeActions.updatePincode(d)),
    validatePincode: d => dispatch(pincodeActions.validatePincode(d)),
    toggleUpdatePincodePopup: d => dispatch(pincodeActions.actions.toggleUpdatePincodePopup(d)),
    toggleValidatePincodePopup: d => dispatch(pincodeActions.actions.toggleValidatePincodePopup(d)),
  },
  neworder: {
    set: d => dispatch(neworderActions.actions.set(d)),
    setServiceOption: d => dispatch(neworderActions.actions.setServiceOption(d)),
    reset: d => dispatch(neworderActions.actions.reset(d)),
    createOrder: d => dispatch(neworderActions.createOrder(d)),
  },
  quotation: {
    get: d => dispatch(quotationActions.getQuotation(d)),
    remove: d => dispatch(quotationActions.removeQuotation(d)),
  },
  general: {
    setDenied: d => dispatch(generalActions.actions.setDenied(d)),
  },
};

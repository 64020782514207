import React from 'react';
import { actions } from '@redux';
import { toast } from 'react-toastify';
import { CircularProgress } from '@mui/material';
import GoogleLogin from 'react-google-login';
// import FacebookLogin from 'react-facebook-login';
import FacebookLogin from 'react-facebook-login/dist/facebook-login-render-props';
import { useSelector } from 'react-redux';
import useLocale from '@helpers/useLocale';
import './login.scss';

export default () => {
  const { t } = useLocale();
  const is_loading = useSelector(s => s.auth.is_loading);

  const onNewUser = () => {
    actions.dialog.setDialog({
      title: t('dialog.newUser.title'),
      body: t('dialog.newUser.body'),
      okTitle: t('dialog.newUser.agreeButton'),
      koTitle: t('dialog.newUser.cancelButton'),
      onOk: () => (window.location.href = '/profile'),
    });
  };

  const responseFacebook = response => {
    if (response.accessToken) {
      actions.auth.thirdPartyLogin({
        token: response.accessToken,
        platform: 'fb',
        onNewUser,
      });
    }
  };

  const responseGoogle = response => {
    if (response.error) {
      // ask user to go to here to allow cookie chrome://settings/cookies
      return toast.error(response.error);
    }
    if (response.tokenObj?.id_token) {
      actions.auth.thirdPartyLogin({
        token: response.tokenObj.id_token,
        platform: 'google',
        onNewUser,
      });
    }
  };

  return (
    <div className="vcs">
      {is_loading ? (
        <div className="center">
          <CircularProgress sx={{ color: '#66fcf1' }} />
        </div>
      ) : (
        <>
          <GoogleLogin
            clientId={process.env.REACT_APP_GOOGLE_ID}
            buttonText="Login"
            onSuccess={responseGoogle}
            onFailure={responseGoogle}
            // cookiePolicy="single_host_origin"
            // redirectUri="https://localhost:3000"
            render={renderProps => (
              <button className="btnGoogle" onClick={renderProps.onClick} disabled={renderProps.disabled}>
                {t('login.google')}
              </button>
            )}
          />
          <FacebookLogin
            appId={process.env.REACT_APP_FACEBOOK_ID}
            disableMobileRedirect
            // autoLoad
            fields="name,email,picture"
            scope="public_profile"
            // onClick={componentClicked}
            render={renderProps => (
              <button className="btnFacebook" onClick={renderProps.onClick}>
                {t('login.fb')}
              </button>
            )}
            callback={responseFacebook}
          />
        </>
      )}
    </div>
  );
};

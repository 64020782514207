import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  isDenied: false,
};

export const slice = createSlice({
  name: 'general',
  initialState,
  reducers: {
    setDenied: (state, action) => {
      state.isDenied = !!action.payload;
    },
  },
});

export const { actions } = slice;

export default slice.reducer;

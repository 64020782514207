import React, { Suspense, useEffect } from 'react';
import { BrowserRouter, Switch, Route } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import useLocale from '@helpers/useLocale';
import { actions } from '@redux';
import Layout from '@layout/main';
import Dialog from '@components/Dialog';
import URLHandler from '@components/URLHandler';
import Denied from '@pages/denied';
import LoginPopup from '@components/LoginPopup';
import PincodePopup from '@components/PincodePopup';
import { useSelector } from 'react-redux';
import { isAuthenticated, isAdminSelector, isApprovedPartnerSelector } from '@redux/selectors/profile';
import routes from './routes';
import ErrorBoundary from './errorBoundary';

export default () => {
  const is_denied = useSelector(s => s.general.isDenied);
  useEffect(actions.config.getAllConfig, []);
  const isAuth = useSelector(isAuthenticated);
  const is_admin = useSelector(isAdminSelector);
  const is_partner = useSelector(isApprovedPartnerSelector);

  const { t } = useLocale();

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>{t('web.title')}</title>
        <link rel="canonical" href="https://mylicin.com" />
        <meta name="description" content={t('web.meta')} />
      </Helmet>
      <BrowserRouter>
        <Switch>
          {routes.map(route => {
            const isDenied = is_denied
              || (!isAuth && route.private)
              || (route.roles?.includes('admin') && !is_admin)
              || (route.roles?.includes('partner') && !is_partner);
            return (
              <Route
                key={route.path || `${Math.random()}`}
                {...route}
                component={p => (
                  <Suspense fallback={() => <div />}>
                    <Layout {...p} fullPage={route.fullPage}>
                      <ErrorBoundary>
                        <URLHandler>
                          {isDenied && <Denied {...p} />}
                          {!isDenied && <route.component {...p} />}
                        </URLHandler>
                      </ErrorBoundary>
                    </Layout>
                  </Suspense>
                )}
              />
            );
          })}
        </Switch>
      </BrowserRouter>
      {isAuth ? <PincodePopup /> : <LoginPopup />}
      <Dialog />
    </>
  );
};

import React from 'react';
import useLocale from '@helpers/useLocale';

export default () => {
  const { t } = useLocale();
  return (
    <>
      <div
        className="w100"
        style={{
          color: '#707070',
          fontSize: '1.5em',
          textAlign: 'center',
        }}
      >
        <h3 className="font-weight-bold">{t('notFound.title')}</h3>
        <div>{t('notFound.body')}</div>
      </div>
    </>
  );
};

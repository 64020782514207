import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import Api, { endpoints } from '@api';

export const getServices = createAsyncThunk('services/get', async (_, thunkAPI) => {
  try {
    return await Api({ endpoint: endpoints.getServices() });
  } catch (e) {
    return thunkAPI.rejectWithValue(e);
  }
});
export const getService = createAsyncThunk('service/get', async (id, thunkAPI) => {
  try {
    return await Api({ endpoint: endpoints.getService(id) });
  } catch (e) {
    return thunkAPI.rejectWithValue(e);
  }
});
export const updateService = createAsyncThunk('service/update', async ({ onSuccess, id, ...args }, thunkAPI) => {
  try {
    await Api({ endpoint: endpoints.updateService(id), ...args });
    onSuccess?.();
  } catch (e) {
    return thunkAPI.rejectWithValue(e);
  }
});
export const createService = createAsyncThunk('services/createService', async ({ onSuccess, ...args }, thunkAPI) => {
  try {
    await Api({ endpoint: endpoints.createService(), ...args });
    onSuccess?.();
  } catch (e) {
    return thunkAPI.rejectWithValue(e);
  }
});

export const updatePartnerService = createAsyncThunk('services/updatePartnerService', async ({ onSuccess, UserId, ServiceId, ...args }, thunkAPI) => {
  try {
    await Api({
      endpoint: endpoints.updatePartnerService(UserId, ServiceId),
      ...args,
    });
    onSuccess?.();
  } catch (e) {
    return thunkAPI.rejectWithValue(e);
  }
});

const initialState = {
  data: [],
  is_loading: false,
  selectedService: null,
  partnerServices: [],
};

export const slice = createSlice({
  name: 'services',
  initialState,
  reducers: {},
  extraReducers: {
    [updateService.fulfilled]: state => {
      state.is_loading = false;
    },
    [updateService.pending]: state => {
      state.is_loading = true;
    },
    [updateService.rejected]: state => {
      state.is_loading = false;
    },
    [updatePartnerService.fulfilled]: state => {
      state.is_loading = false;
    },
    [updatePartnerService.pending]: state => {
      state.is_loading = true;
    },
    [updatePartnerService.rejected]: state => {
      state.is_loading = false;
    },
    [createService.fulfilled]: state => {
      state.is_loading = false;
    },
    [createService.pending]: state => {
      state.is_loading = true;
    },
    [createService.rejected]: state => {
      state.is_loading = false;
    },
    [getServices.fulfilled]: (state, action) => {
      state.is_loading = false;
      state.data = action.payload.data.rows;
    },
    [getServices.pending]: state => {
      state.is_loading = true;
    },
    [getServices.rejected]: state => {
      state.is_loading = false;
    },
    [getService.fulfilled]: (state, action) => {
      state.is_loading = false;
      let parsedName;
      let parsedQuotationTnc;
      const { name, quotationTnc, ...others } = action.payload.data;
      try {
        parsedName = JSON.parse(name);
        parsedQuotationTnc = JSON.parse(quotationTnc);
      } catch (e) {
        console.log(e);
      }

      state.selectedService = { ...others, name: parsedName, quotationTnc: parsedQuotationTnc };
    },
    [getService.pending]: state => {
      state.is_loading = true;
    },
    [getService.rejected]: state => {
      state.is_loading = false;
    },
  },
});

export const { actions } = slice;

export default slice.reducer;

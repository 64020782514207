import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
// import { getProfileFromCookie, getTokenCookie } from '@helpers/auth';
// import { Cookies } from 'react-cookie';
import Api, { endpoints } from '@api';

const logoutFromBrowser = () => {
  // const cookie = new Cookies();
  // cookie.remove('token');
  // cookie.remove('refreshToken');
  // cookie.remove('profile');
  // window.location.href = '/';
};

export const addressList = createAsyncThunk('auth/addressList', async thunkAPI => {
  try {
    return await Api({ endpoint: endpoints.addressList() });
  } catch (e) {
    return thunkAPI.rejectWithValue(e);
  }
});

export const updateProfile = createAsyncThunk('auth/updateProfile', async (arg, thunkAPI) => {
  try {
    return await Api({ endpoint: endpoints.updateProfile(), ...arg });
  } catch (e) {
    return thunkAPI.rejectWithValue(e);
  }
});
export const deleteProfile = createAsyncThunk('auth/deleteProfile', async (_, thunkAPI) => {
  try {
    await Api({ endpoint: endpoints.deleteProfile() });
  } catch (e) {
    return thunkAPI.rejectWithValue(e);
  }
});

export const thirdPartyLogin = createAsyncThunk('auth/thirdPartyLogin', async ({ token, platform, onNewUser }, thunkAPI) => {
  const endpoint = platform === 'google' ? endpoints.loginWithGoogle() : endpoints.loginWithFB();
  try {
    const response = await Api({ endpoint, data: { token } });
    if (response.is_new) {
      onNewUser();
    }
    return response;
  } catch (e) {
    return thunkAPI.rejectWithValue(e);
  }
});

export const logout = createAsyncThunk('auth/logout', async (_, thunkAPI) => {
  try {
    // to implement online logout later
  } catch (e) {
    return thunkAPI.rejectWithValue(e);
  }
});

export const getProfile = createAsyncThunk('auth/getProfile', async (_, thunkAPI) => {
  try {
    return await Api({ endpoint: endpoints.getProfile() });
  } catch (e) {
    return thunkAPI.rejectWithValue(e);
  }
});

const initialState = {
  profile: null, // getProfileFromCookie(),
  token: null, // getTokenCookie(),
  is_loading: false,
  is_show_login_popup: false,
  addressList: [],
};

export const slice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    togglePopup: (state, action) => {
      state.is_show_login_popup = action.payload;
    },
  },
  extraReducers: {
    [addressList.pending]: state => {
      state.is_loading = true;
    },
    [addressList.rejected]: state => {
      state.is_loading = false;
    },
    [addressList.fulfilled]: (state, action) => {
      state.is_loading = false;
      state.addressList = action.payload.data;
    },
    [updateProfile.pending]: state => {
      state.is_loading = true;
    },
    [updateProfile.rejected]: state => {
      state.is_loading = false;
    },
    [updateProfile.fulfilled]: (state, action) => {
      state.is_loading = false;
      state.profile = action.payload.data;
      // const cookie = new Cookies();
      // cookie.set('profile', JSON.stringify(action.payload.data));
    },
    [getProfile.pending]: state => {
      state.is_loading = true;
    },
    [getProfile.rejected]: state => {
      state.is_loading = false;
    },
    [getProfile.fulfilled]: (state, action) => {
      state.is_loading = false;
      state.profile = action.payload.data;
      // const cookie = new Cookies();
      // cookie.set('profile', JSON.stringify(action.payload.data));
    },
    [thirdPartyLogin.pending]: state => {
      state.is_loading = true;
    },
    [thirdPartyLogin.rejected]: state => {
      state.is_loading = false;
    },
    [thirdPartyLogin.fulfilled]: (state, action) => {
      state.is_loading = false;
      state.token = action.payload.token;
      state.refreshToken = action.payload.refreshToken;
      state.profile = action.payload.profile;

      // const cookie = new Cookies();
      // cookie.set('token', action.payload.token);
      // cookie.set('refreshToken', action.payload.refreshToken);
      // cookie.set('profile', JSON.stringify(action.payload.profile));
    },
    [deleteProfile.pending]: state => {
      state.is_loading = true;
    },
    [deleteProfile.rejected]: state => {
      state.is_loading = false;
    },
    [deleteProfile.fulfilled]: state => {
      state.is_loading = false;
      state.token = null;
      state.refreshToken = null;
      state.profile = null;
      logoutFromBrowser();
    },
    [logout.fulfilled]: state => {
      state.is_loading = false;
      state.token = null;
      state.refreshToken = null;
      state.profile = null;
      logoutFromBrowser();
    },
  },
});

export const { actions } = slice;

export default slice.reducer;

export const URL = process.env.REACT_APP_URL;

const _id = id => (id ? `/${id}` : '');

export const endpoints = {
  loginWithGoogle: () => ['POST', `${URL}login-with-google`],
  loginWithFB: () => ['POST', `${URL}login-with-fb`],
  getServices: () => ['GET', `${URL}services`],
  // require token
  getProfile: id => ['GET', `${URL}api/user${_id(id)}`],
  updateProfile: id => ['POST', `${URL}api/user${_id(id)}`],
  deleteProfile: id => ['DELETE', `${URL}api/user${_id(id)}`],
  submitPartnerRequest: id => ['POST', `${URL}api/submitPartnerRequest${_id(id)}`],
  cancelPartnerRequest: id => ['POST', `${URL}api/cancelPartnerRequest${_id(id)}`],
  // admin
  getUsers: () => ['GET', `${URL}api/users`],
  approvePartner: id => ['POST', `${URL}api/approvePartner/${id}`],
  makeAdmin: id => ['POST', `${URL}api/makeAdmin/${id}`],
  cancelAdmin: id => ['POST', `${URL}api/cancelAdmin/${id}`],
  cancelPartnerApproval: id => ['POST', `${URL}api/cancelPartnerApproval/${id}`],

  createService: () => ['POST', `${URL}api/service`],
  getService: id => ['GET', `${URL}api/service/${id}`],
  updateService: id => ['POST', `${URL}api/service/${id}`],
  deleteService: id => ['DELETE', `${URL}api/service/${id}`],

  getOrders: () => ['GET', `${URL}api/orders`],
  getOrder: id => ['GET', `${URL}api/order/${id}`],
  createOrder: () => ['POST', `${URL}api/order`],
  cancelOrder: id => ['POST', `${URL}api/order/cancel/${id}`],
  closeOrder: id => ['POST', `${URL}api/order/close/${id}`],
  startJob: id => ['POST', `${URL}api/order/startJob/${id}`],
  cancelJob: id => ['POST', `${URL}api/job/cancel/${id}`],
  endJob: id => ['POST', `${URL}api/order/endJob/${id}`],
  sendQuotation: () => ['POST', `${URL}api/quotation`],
  getQuotation: id => ['GET', `${URL}api/quotation/${id}`],
  removeQuotation: id => ['DELETE', `${URL}api/quotation/${id}`],
  acceptOrder: id => ['POST', `${URL}api/order/accept/${id}`],

  updatePincode: () => ['POST', `${URL}api/pincode/update`],
  validatePincode: () => ['POST', `${URL}api/pincode/validate`],
  resetPincode: id => ['POST', `${URL}api/pincode/reset/${id}`],

  updatePartnerService: (UserId, ServiceId) => ['POST', `${URL}api/partnerService/${UserId}/${ServiceId}`],

  createUserChangeRequest: id => ['POST', `${URL}api/userChangeRequest${_id(id)}`],
  cancelUserChangeRequest: id => ['POST', `${URL}api/cancelUserChangeRequest${_id(id)}`],
  approveUserChangeRequest: id => ['POST', `${URL}api/approveUserChangeRequest/${id}`],
  rejectUserChangeRequest: id => ['POST', `${URL}api/rejectUserChangeRequest/${id}`],
  paymentGatewayBill: id => ['GET', `${URL}bill/${id}`],

  addressList: () => ['GET', `${URL}api/address/oldList`],
};

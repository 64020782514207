import React from 'react';
import useLocale from '@helpers/useLocale';
import Button from '@components/Button';
import { actions } from '@redux';
import { useSelector } from 'react-redux';
import { isAuthenticated } from '@redux/selectors/profile';

export default () => {
  const { t } = useLocale();
  const isAuth = useSelector(isAuthenticated);
  return (
    <div className="center" style={{ color: '#707070', fontSize: '1.5em', width: '100%' }}>
      <div className="tacenter">
        <h3>
          <b>{t('denied.title')}</b>
        </h3>
        <div>{t('denied.body')}</div>
        {!isAuth && <Button onClick={() => actions.auth.togglePopup(true)}>{t('denied.login')}</Button>}
      </div>
    </div>
  );
};

import * as React from 'react';
import Box from '@mui/material/Box';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Typography from '@mui/material/Typography';
import { Link, useLocation } from 'react-router-dom';
import Stack from '@mui/material/Stack';
import HomeIcon from '@mui/icons-material/Home';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';

export default () => {
  const location = useLocation();
  const pathnames = location.pathname.split('/').filter(Boolean);
  if (!pathnames.length) return null;

  return (
    <Stack spacing={2} sx={{ mb: 3 }}>
      <Breadcrumbs separator={<NavigateNextIcon fontSize="small" />} aria-label="breadcrumb">
        <Link to="/">
          <HomeIcon sx={{ mb: -0.5, color: 'black' }} />
        </Link>
        {pathnames.map((value, index) => {
          const last = index === pathnames.length - 1;
          const to = `/${pathnames.slice(0, index + 1).join('/')}`;

          return last ? (
            <Typography sx={{ textTransform: 'capitalize' }} color="text.primary" key={to}>
              {value.replaceAll('-', ' ')}
            </Typography>
          ) : (
            <Link style={{ color: 'black', textTransform: 'capitalize' }} to={to} key={to}>
              <Box sx={{ '&:hover': { textDecoration: 'underline black' } }}>{value.replaceAll('-', ' ')}</Box>
            </Link>
          );
        })}
      </Breadcrumbs>
    </Stack>
  );
};

import React from 'react';
import { ListItemIcon, ListItemText, ListItem, List, Divider, Toolbar } from '@mui/material';
import { AccountCircle, Groups, ShoppingCart, Build, FormatListBulleted, AddShoppingCart } from '@mui/icons-material';
import logo from '@res/logo.png';
import useLocale from '@helpers/useLocale';
import { Link } from 'react-router-dom';
import { isAdminSelector, isApprovedPartnerSelector, isDropServiceSelector } from '@redux/selectors/profile';
import { useSelector } from 'react-redux';
import packageJson from '../../../package.json';

const MenuItem = p => {
  const hasPath = !!p.path;
  return (
    <Link to={p.path} onClick={!!p.path ? p.close : undefined}>
      <ListItem button={hasPath} key={p.label}>
        {!!p.icon && (
          <ListItemIcon>
            <p.icon />
          </ListItemIcon>
        )}
        <ListItemText style={{ color: hasPath ? 'black' : 'gray' }} primary={p.label} />
      </ListItem>
    </Link>
  );
};

export default ({ close }) => {
  const { t } = useLocale();
  const isAdmin = useSelector(isAdminSelector);
  const isPartner = useSelector(isApprovedPartnerSelector);
  const isDropService = useSelector(isDropServiceSelector);
  const menus = [
    {
      path: '/profile',
      label: t('drawer.profile'),
      icon: AccountCircle,
    },
    {
      path: '/new-order',
      label: t('drawer.neworder'),
      icon: AddShoppingCart,
    },
    {
      path: '/orders',
      label: t('drawer.orders'),
      icon: ShoppingCart,
    },
  ];
  const adminMenus = isAdmin
    ? [
        {
          label: t('drawer.titleAdmin'),
        },
        {
          path: '/users',
          label: t('drawer.users'),
          icon: Groups,
        },
        {
          path: '/services',
          label: t('drawer.services'),
          icon: FormatListBulleted,
        },
        {
          path: '/orders?mode=all',
          label: t('drawer.orders'),
          icon: ShoppingCart,
        },
      ]
    : [];
  const dropServiceMenus = isDropService
    ? [
        // {
        //   label: t('drawer.titleDropService'),
        // },
        // {
        //   path: '/orders?mode=jobs',
        //   label: t('drawer.history'),
        //   icon: History,
        // },
      ]
    : [];

  const partnerMenus = isPartner
    ? [
        {
          label: t('drawer.titlePartner'),
        },
        {
          path: '/new-jobs',
          label: t('drawer.newjobs'),
          icon: Build,
        },
        {
          path: '/accepted-jobs',
          label: t('drawer.job'),
          icon: Build,
        },
        // {
        //   path: '/quotations',
        //   label: t('drawer.quotations'),
        //   icon: AllInbox,
        // },
      ]
    : [];
  return (
    <div>
      <Toolbar>
        <div style={{ marginTop: 30 }}>
          <img src={logo} style={{ width: 100 }} />
        </div>
      </Toolbar>
      <Divider />
      <List>
        {menus.map(i => (
          <MenuItem {...i} close={close} />
        ))}
      </List>
      {isAdmin && (
        <>
          <Divider />
          <List>
            {adminMenus.map(i => (
              <MenuItem {...i} close={close} />
            ))}
          </List>
        </>
      )}
      {isPartner && (
        <>
          <Divider />
          <List>
            {partnerMenus.map(i => (
              <MenuItem {...i} close={close} />
            ))}
          </List>
        </>
      )}
      {isDropService && (
        <>
          <Divider />
          <List>
            {dropServiceMenus.map(i => (
              <MenuItem {...i} close={close} />
            ))}
          </List>
        </>
      )}
      <div className="dimTxt" style={{ position: 'fixed', bottom: 0, textAlign: 'left', padding: 10 }}>
        <div>{packageJson.version}</div>
      </div>
    </div>
  );
};

import { createSlice } from '@reduxjs/toolkit';

// data: {
//   title: '',
//   body: '',
//   okTitle: '',
//   koTitle: '',
//   onOk: () => null,
//   onKo: () => null,
// },
const initialState = {
  data: null,
};

export const slice = createSlice({
  name: 'dialog',
  initialState,
  reducers: {
    setDialog: (state, action) => {
      state.data = action.payload;
    },
  },
});

export const { actions } = slice;

export default slice.reducer;

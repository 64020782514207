import React, { useState, useEffect } from 'react';
import useLocale from '@helpers/useLocale';
import Button from '@components/Button';
import { DialogContent, DialogTitle, Dialog, TextField } from '@mui/material';
import { actions } from '@redux';
import Transition from '@components/DialogTransition';
import { useSelector } from 'react-redux';

export default function AlertDialogSlide() {
  const [currentPincode, setCurrentPincode] = useState('');
  const [currentPincodeError, setCurrentPincodeError] = useState('');
  const [newPincode, setNewPincode] = useState('');
  const [newPincodeError, setNewPincodeError] = useState('');
  const [renewPincode, setRenewPincode] = useState('');
  const [renewPincodeError, setRenewPincodeError] = useState('');
  const hasPincode = useSelector(s => !!s.auth.profile.pincode);
  const { is_loading, is_show_update_pincode_popup, on_success_validate } = useSelector(s => s.pincode);
  const open = is_show_update_pincode_popup || on_success_validate;
  const { t } = useLocale();

  const reset = () => {
    setCurrentPincode('');
    setCurrentPincodeError('');
    setNewPincode('');
    setNewPincodeError('');
    setRenewPincode('');
    setRenewPincodeError('');
  };

  useEffect(() => setCurrentPincodeError(''), [currentPincode]);
  useEffect(() => setNewPincodeError(''), [newPincode]);
  useEffect(() => setRenewPincodeError(''), [newPincode, renewPincode]);

  const handleClose = () => {
    actions.pincode.toggleUpdatePincodePopup(false);
    reset();
  };

  const validate = () => {
    if (!currentPincode) return setCurrentPincodeError(t('pincode.cannotEmpty'));
    actions.pincode.validatePincode({ pincode: currentPincode });
  };

  const submit = () => {
    if (hasPincode && !currentPincode) return setCurrentPincodeError(t('pincode.cannotEmpty'));
    if (is_show_update_pincode_popup && newPincode !== renewPincode) return setRenewPincodeError(t('pincode.renewPincodeNotMatch'));
    if (is_show_update_pincode_popup && !newPincode) return setNewPincodeError(t('pincode.cannotEmpty'));
    if (is_show_update_pincode_popup) {
      actions.pincode.updatePincode({
        data: {
          new_pincode: newPincode,
          current_pincode: currentPincode,
        },
        onSuccess: () => {
          actions.auth.getProfile();
          handleClose();
        },
        onError: e => setCurrentPincodeError(JSON.stringify(e)),
      });
    }
  };

  return (
    <Dialog
      open={open}
      TransitionComponent={Transition}
      keepMounted
      onClose={handleClose}
      aria-describedby="alert-dialog-slide-description"
      // style={{ backgroundColor: '#272727' }}
      PaperProps={{ style: { backgroundColor: 'white' } }}
    >
      <DialogTitle>{is_show_update_pincode_popup ? t('pincode.updatePincode') : t('pincode.enterPincode')}</DialogTitle>
      <DialogContent className="vcs">
        <div style={{ display: 'grid', gap: '1rem', padding: '1rem' }} className="tacenter">
          {hasPincode && (
            <TextField
              error={!!currentPincodeError}
              helperText={currentPincodeError}
              label={t('pincode.currentPincode')}
              disabled={is_loading}
              value={currentPincode}
              type="password"
              onChange={e => setCurrentPincode(e.target.value)}
              fullWidth
            />
          )}
          {is_show_update_pincode_popup && (
            <>
              <TextField
                error={!!newPincodeError}
                helperText={newPincodeError}
                label={t('pincode.newPincode')}
                type="password"
                disabled={is_loading}
                value={newPincode}
                onChange={e => setNewPincode(e.target.value)}
                fullWidth
              />
              <TextField
                error={!!renewPincodeError}
                helperText={renewPincodeError}
                label={t('pincode.renewPincode')}
                type="password"
                disabled={is_loading}
                value={renewPincode}
                onChange={e => setRenewPincode(e.target.value)}
                fullWidth
              />
            </>
          )}
          <Button onClick={is_show_update_pincode_popup ? submit : validate} isLoading={is_loading}>
            {t('pincode.submit')}
          </Button>
        </div>
      </DialogContent>
    </Dialog>
  );
}

import React from 'react';
import { Container } from '@mui/material';
import Header from '@components/Header';
import Breadcrumb from '@components/Breadcrumb';
import Footer from '@components/Footer';

const Layout = ({ children, fullPage }) => (
  <>
    <div style={{ position: 'absolute', width: '100%' }}>
      <Header />
    </div>
    <div style={{ height: '100vh', paddingTop: fullPage ? '3rem' : '5rem' }}>
      {fullPage ? (
        children
      ) : (
        <Container maxWidth="md" className="page-container">
          <div>
            <Breadcrumb />
            {children}
          </div>
          <Footer />
        </Container>
      )}
    </div>
  </>
);

export default Layout;

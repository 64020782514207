/* eslint-disable complexity, max-params */
import { useSelector } from 'react-redux';

export default () => {
  const lang = useSelector(s => s.auth.profile?.language) || 'en';
  const locale = useSelector(s => s.config?.language?.[lang]) || {};

  const t = (path, params, isNonString, defaultValue) => {
    const paths = path.split('.');
    let sub = { ...locale };
    for (let i = 0; i < paths.length; i++) {
      sub = sub[paths[i]];
      if (!sub) return defaultValue || path;
    }
    if (typeof sub === 'string') {
      if (params) {
        // eslint-disable-next-line
        for (const [key, value] of Object.entries(params)) {
          const regex = RegExp(`{${key}}`, 'g');
          let matches;
          // eslint-disable-next-line no-cond-assign, max-depth
          while ((matches = regex.exec(sub)) !== null) sub = sub.replace(matches[0], `${value}`);
        }
      }
      return sub;
    }
    if (isNonString) return sub || defaultValue;
    return defaultValue || path;
  };

  return {
    t,
  };
};

/* eslint-disable max-lines */
// this is id from database
export const ids = {
  clothFolding: 3,
  lawnCut: 2,
  maid: 1,
};

const lawnTicks = [
  {
    id: 'sweep-throw',
    type: 'tick',
    name: {
      en: 'Sweep and throw',
      my: 'Sapu dan buang',
    },
  },
  {
    id: 'cut-small-trees',
    type: 'tick',
    name: {
      en: 'Cut small trees',
      my: 'Tebas pokok kecil',
    },
  },
  {
    id: 'wash-drain',
    type: 'tick',
    name: {
      en: 'Wash drain',
      my: 'Cuci longkang',
    },
  },
  {
    id: 'bulldozer',
    type: 'tick',
    name: {
      en: 'Bulldozer service',
      my: 'Khidmat jentolak',
    },
  },
];

const clothTicks = [
  {
    id: 'wash-dry',
    name: {
      en: 'Wash & dry',
      my: 'Basuh dan keringkan',
    },
    type: 'tick',
  },
  {
    id: 'iron',
    name: {
      en: 'Iron',
      my: 'Seterika',
    },
    input: 'number',
    requireImage: true,
    inputLabel: {
      en: 'How many clothes to be ironed',
      my: 'Berapa helai pakaian untuk diseterika',
    },
    inputUnit: {
      en: 'clothes',
      my: 'helai',
    },
    type: 'tick',
  },
  {
    id: 'deodorizer',
    name: {
      en: 'Deodorizer',
      my: 'Pewangi',
    },
    type: 'tick',
  },
];

export default {
  serviceOptions: {
    default: [],
    [ids.clothFolding]: [
      {
        id: 'weight',
        name: {
          en: 'Weight of clothes (KG)',
          my: 'Berat pakaian (KG)',
        },
        type: 'number',
        isSubjectToChange: true,
      },
      ...clothTicks,
      {
        id: 'images',
        type: 'images',
        name: {
          en: 'Clothes images',
          my: 'Gambar-gambar pakaian',
        },
        instruction: {
          en: 'Please upload cloth images',
          my: 'Sila muat naik semua gambar pakaian',
        },
        optional: true,
      },
      {
        id: 'datetime-from',
        name: {
          en: 'Date & time to pick',
          my: 'Tarikh & masa ambil',
        },
        type: 'datetime',
      },
      {
        id: 'datetime-to',
        name: {
          en: 'Date & time done',
          my: 'Tarik & Waktu siap',
        },
        type: 'datetime',
      },
    ],
    [ids.lawnCut]: [
      {
        id: 'premise-type',
        name: {
          en: 'Premise type',
          my: 'Jenis premis',
        },
        type: 'select',
        options: [
          {
            value: 'Terrace',
            en: 'Terrace',
            my: 'Teres',
          },
          {
            value: 'Bungalow',
            en: 'Bungalow',
            my: 'Banglo',
          },
          {
            value: 'Semi D',
            en: 'Semi D',
            my: 'Semi D',
          },
          {
            value: 'Office',
            en: 'Office',
            my: 'Pejabat',
          },
        ],
      },
      {
        id: 'size',
        name: {
          en: 'Lawn area (sq. ft.)',
          my: 'Luas kawasan berumput (kaki persegi)',
        },
        type: 'number',
        isSubjectToChange: true,
        optional: true,
      },
      ...lawnTicks,
      {
        id: 'images',
        type: 'images',
        name: {
          en: 'Premise images',
          my: 'Gambar-gambar premis',
        },
        instruction: {
          en: 'Please upload lawn images',
          my: 'Sila muat naik semua gambar rumput',
        },
      },
      {
        id: 'datetime',
        name: {
          en: 'Date & Time',
          my: 'Tarikh & Masa',
        },
        type: 'datetime',
      },
    ],
    [ids.maid]: [
      {
        id: 'number-of-worker',
        name: {
          en: 'Number of maid',
          my: 'Jumlah orang gaji',
        },
        type: 'multiplier',
        value: 1,
      },
      {
        id: 'images',
        type: 'images',
        name: {
          en: 'Premise images',
          my: 'Gambar-gambar premis',
        },
        instruction: {
          en: 'Please upload images of part of the house that need to be cleaned',
          my: 'Sila muat naik semua gambar bahagian rumah yang perlu dikemas',
        },
      },
      {
        id: 'date',
        name: {
          en: 'Date',
          my: 'Tarikh',
        },
        type: 'date',
      },
      // {
      //   id: 'date-from',
      //   name: {
      //     en: 'Date from',
      //     my: 'Tarikh bermula',
      //   },
      //   type: 'date',
      // },
      // {
      //   id: 'date-to',
      //   name: {
      //     en: 'Date to',
      //     my: 'Tarikh tamat',
      //   },
      //   type: 'date',
      //   optional: true,
      // },
      {
        id: 'time-from',
        name: {
          en: 'Time from',
          my: 'Masa dari',
        },
        type: 'time',
      },
      {
        id: 'time-to',
        name: {
          en: 'Time to',
          my: 'Masa sehingga',
        },
        type: 'time',
      },
    ],
  },
  jobOptions: {
    [ids.clothFolding]: [
      {
        id: 'price-per-weight',
        serviceOptionsId: 'weight',
        name: {
          en: 'Price per kg',
          my: 'Harga setiap kg',
        },
        type: 'number',
      },
      ...clothTicks.map(l => ({ ...l, serviceOptionsId: l.id })),
    ],
    [ids.lawnCut]: [
      {
        id: 'base-price',
        serviceOptionsId: ['premise-type', 'size'],
        name: {
          en: 'Total price',
          my: 'Harga keseluruhan',
        },
        type: 'number',
        secondaryOption: {
          name: {
            en: 'Price per square feet',
            my: 'Harga per kaki',
          },
        },
      },
      ...lawnTicks.map(l => ({ ...l, serviceOptionsId: l.id })),
    ],
    [ids.maid]: [
      // {
      //   serviceOptionsId: 'number-of-worker',
      //   name: {
      //     en: 'Price per maid',
      //     my: 'Harga setiap orang gaji',
      //   },
      //   type: 'number',
      // },
      {
        id: 'price-per-hour',
        serviceOptionsId: ['date-from', 'date-to', 'time-from', 'time-to'],
        name: {
          en: 'Price per hour',
          my: 'Harga setiap jam',
        },
        type: 'number',
      },
    ],
    default: [],
  },
};

import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import Api, { endpoints } from '@api';
import configs from '@configs';

// import { stringToObjectChild } from '@helpers';

export const createOrder = createAsyncThunk('neworder/create', async (obj, thunkAPI) => {
  const { onSuccess, onFail, ...arg } = obj;
  try {
    await Api({ endpoint: endpoints.createOrder(), ...arg });
    onSuccess?.();
  } catch (e) {
    onFail?.();
    return thunkAPI.rejectWithValue(e);
  }
});

const initialState = {
  ServiceId: null,
  serviceOptions: null,
  address: null,
  datetime: null,
  phone: null,
  is_confirm_service: null,
  is_confirm_address: null,
  is_confirm_datetime: null,
  gps: null,
  comment: null,
  is_loading: false,
};

export const slice = createSlice({
  name: 'neworder',
  initialState,
  reducers: {
    set: (s, a) => {
      const [key, value] = a.payload;
      s[key] = value;
      if (key === 'ServiceId') {
        s.serviceOptions = configs.serviceOptions[value] || configs.serviceOptions.default;
      }
      // stringToObjectChild(a.payload[0]);
      // const paths = a.payload[0].split('.');
    },
    setServiceOption: (s, a) => {
      const [id, value] = a.payload;
      const ind = s.serviceOptions.findIndex(so => so.id === id);
      if (!~ind) {
        console.log(`error service not found id:${id}, value:${value}`);
        return;
      }
      s.serviceOptions[ind].value = value;
    },
    reset: s => {
      // eslint-disable-next-line no-restricted-syntax, guard-for-in
      for (const k in initialState) {
        s[k] = initialState[k];
      }
    },
  },
  extraReducers: {
    [createOrder.pending]: state => {
      state.is_loading = true;
    },
    [createOrder.rejected]: state => {
      state.is_loading = false;
    },
    [createOrder.fulfilled]: s => {
      // eslint-disable-next-line no-restricted-syntax, guard-for-in
      for (const k in initialState) {
        s[k] = initialState[k];
      }
    },
  },
});

export const { actions } = slice;

export default slice.reducer;

import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import Api, { endpoints } from '@api';

export const validatePincode = createAsyncThunk('pincode/validate', async ({ pincode }, thunkAPI) => {
  try {
    await Api({ endpoint: endpoints.validatePincode(), data: { pincode } });
    thunkAPI.getState().pincode.on_success_validate?.();
  } catch (e) {
    thunkAPI.getState().pincode.on_fail_validate?.();
    return thunkAPI.rejectWithValue(e);
  }
});
export const resetPincode = createAsyncThunk('pincode/reset', async ({ id, onSuccess }, thunkAPI) => {
  try {
    await Api({ endpoint: endpoints.resetPincode(id) });
    onSuccess?.();
  } catch (e) {
    return thunkAPI.rejectWithValue(e);
  }
});
export const updatePincode = createAsyncThunk('pincode/update', async ({ data, onSuccess, onError }, thunkAPI) => {
  try {
    await Api({ endpoint: endpoints.updatePincode(), data });
    onSuccess?.();
  } catch (e) {
    onError?.(e);
    return thunkAPI.rejectWithValue(e);
  }
});

const initialState = {
  is_loading: false,
  is_show_update_pincode_popup: false,
  on_success_validate: null,
  on_fail_validate: null,
};

export const slice = createSlice({
  name: 'pincode',
  initialState,
  reducers: {
    toggleUpdatePincodePopup: (state, action) => {
      state.is_show_update_pincode_popup = action.payload;
    },
    toggleValidatePincodePopup: (state, action) => {
      state.on_success_validate = action.payload?.onSuccess;
      state.on_fail_validate = action.payload?.onFail;
    },
  },
  extraReducers: {
    [validatePincode.fulfilled]: state => {
      state.is_loading = false;
      state.on_success_validate = null;
      state.on_fail_validate = null;
    },
    [validatePincode.pending]: state => {
      state.is_loading = true;
    },
    [validatePincode.rejected]: state => {
      state.is_loading = false;
      state.on_success_validate = null;
      state.on_fail_validate = null;
    },
    [resetPincode.fulfilled]: state => {
      state.is_loading = false;
    },
    [resetPincode.pending]: state => {
      state.is_loading = true;
    },
    [resetPincode.rejected]: state => {
      state.is_loading = false;
    },
    [updatePincode.fulfilled]: state => {
      state.is_loading = false;
    },
    [updatePincode.pending]: state => {
      state.is_loading = true;
    },
    [updatePincode.rejected]: state => {
      state.is_loading = false;
    },
  },
});

export const { actions } = slice;

export default slice.reducer;
